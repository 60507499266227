export const CHECKED_FEATURES = 'ethos_allFeaturesChecked'

/**
 * Appends Optimizely feature to window.sessionStorage
 */
export const appendFeatureToSessionStorage = (
  featureKey: string,
  id: string,
  featureEnabled: boolean
): void => {
  if (typeof featureKey !== 'string') return

  try {
    const current = window.sessionStorage.getItem(CHECKED_FEATURES) || ''
    const latest = `${current}|${featureKey}-${id}-${featureEnabled}`
    window.sessionStorage.setItem(CHECKED_FEATURES, latest)
  } catch (err) {
    if (err instanceof Error) {
      console.warn('session storage failure; feature not appended', err.stack)
    }
  }
}

/**
 * Checks if Optimizely Feature is present in window.sessionStorage
 */
export const featurePreviouslyChecked = (
  featureKey: string,
  id: string,
  featureEnabled: boolean
): boolean => {
  try {
    const current = window.sessionStorage.getItem(CHECKED_FEATURES) || ''
    return current.includes(`${featureKey}-${id}-${featureEnabled}`)
  } catch (err) {
    if (err instanceof Error) {
      console.warn(
        'session storage failure; previously checked unknown',
        err.stack
      )
    }
    return false
  }
}

// interface for configs passed around for experiments and feature flags
export interface FeatureFlagConfig {
  useFinalExpensePriceRange?: boolean // for experiments
  usePriceElasticity?: boolean
  priceAnchoring?: boolean
  useLean?: boolean
  useRatesCopy?: boolean
  useDailyPremium?: boolean
  useMedianValues?: boolean
}
