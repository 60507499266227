/**
 * Checks a whitelist of partners that should recieve the D2C experience during their application.
 * ---
 * @param {string} partnerCode: p code from the query string / pathname / clientData or cookie
 * @returns {Boolean}
 */
export const isNonAgentPartner = ({ partnerCode }: { partnerCode: string }) => {
  if (!partnerCode || typeof partnerCode === 'undefined' || partnerCode === '') return false
  const nonAgentPartnerCodes = [
    'creditkarma',
    'ethosNonAgent', // for internal testing purposes only
  ]
  return nonAgentPartnerCodes.includes(partnerCode)
}
