import { AnalyticsBrowser } from '@segment/analytics-next'
import { PREFILL_PII_PROPS } from 'lib/@getethos/urls/constants'
import { getFullStoryEnabled } from 'lib/FullStory/getFullStoryEnabled'
import qs from 'qs'

export const analytics = new AnalyticsBrowser()

const SEGMENT_KEY =
  process.env.NEXT_PUBLIC_ETHOS_ENV === 'production'
    ? 'xcRQi5gZMyuuvlfyLmqamOT6xqwNJ5so'
    : 'CeHKjsWaVBeJwRfeFpO1977HzzEWgk1i'

const removePIIParams = (q: string) => {
  const queryObj = qs.parse(q)
  Object.keys(queryObj).forEach((k) => {
    if (PREFILL_PII_PROPS.includes(k)) {
      delete queryObj[k]
    }
  })
  delete queryObj['martech_data']
  return qs.stringify(queryObj, { encodeValuesOnly: true })
}
export const useAnalytics = () => {
  const loadAnalytics = () => {
    // if content is loaded, load analytics

    analytics.load(
      {
        writeKey: SEGMENT_KEY,
        cdnURL: 'https://segment.ethoslife.com',
      },
      {
        // TODO: We may need to disable some integrations that are not needed
        integrations: {
          FullStory: getFullStoryEnabled(document.location.pathname),
        },
      }
    )

    if (typeof window.ga === 'function') {
      window.ga('require', 'linker')

      window.ga('linker:autoLink', [
        'ethoslife.com',
        'app.ethoslife.com',
        'try.ethoslife.com',
      ])
    }
  }

  const pageTrack = () => {
    // if (!window.analytics) return
    // Manually track the referrer using window.location.href
    // since document.referrer doesn't always change onRouteUpdate
    window.locations = window.locations || [document.referrer]
    window.locations.push(window.location.href)
    window.previousPath = window.locations[window.locations.length - 2]

    // // Being extra explicit defining these Segment properties since
    // // automatic implementation of the plugin was sometimes leaving
    // // some properties blank.
    const baseUrl = location.href.split('?')[0]
    const searchString = location.search.replace('?', '')
    const pageProps = {
      referrer: window.previousPath,
      search: location.search === '' ? '' : '?' + removePIIParams(searchString),
      url:
        location.search === ''
          ? location.href
          : baseUrl + '?' + removePIIParams(searchString),
      title: document.title,
      path: location.pathname,
      client: 'cms-next',
    }

    analytics.page(location.pathname, pageProps)
  }

  return {
    loadAnalytics,
    pageTrack,
  }
}
