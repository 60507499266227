const OPTIMIZELY_FULLSTACK = {
  development: 'QZBQbZ3wn9x9mjFdZxPsPP',
  test: 'Y631vP3eFfcy9TGhBCaodX',
  production: '47sAJkret1FpH9TRioXYE4',
}
const getOptimizelyKey = ({ env = process.env.NEXT_PUBLIC_ETHOS_ENV } = {}) => {
  return env === 'localhost'
    ? OPTIMIZELY_FULLSTACK.development
    : env === 'staging' || env === 'netlify'
    ? OPTIMIZELY_FULLSTACK.test
    : OPTIMIZELY_FULLSTACK.production
}

exports.getOptimizelyKey = getOptimizelyKey

exports.OPTIMIZELY_FULLSTACK = OPTIMIZELY_FULLSTACK
