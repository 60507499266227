import Cookies from 'js-cookie'

const NOT_ALLOWED_HOSTNAMES = ['localhost', 'netlify']

/**
 * Returns only the top-level domain of a given hostname,
 * stripped of any subdomains and periods preceding the TLD
 */
export function getDomainName(hostname = ''): string {
  const parts = hostname
    .replace(/^(\w+:)?\/\//, '') // strip protocol if exists
    .split('.')

  // Return everything if something like ['ethoslife', 'com']
  if (parts.length <= 2) return parts.join('.')

  // Return everything except the first subdomain,
  // allowing sub-subdomains like ['stage', 'ethoslife', 'com']
  return parts.slice(1).join('.')
}

export function getCookieDomain(hostname: string): string | undefined {
  return hostname &&
    !NOT_ALLOWED_HOSTNAMES.some((name) => hostname.includes(name))
    ? getDomainName(hostname)
    : undefined
}

export function getCookieSettings({
  hostname = window.location.hostname,
} = {}) {
  const isLocalhost = hostname === 'localhost'
  const isTestDomain = /\.test$/.test(hostname)
  const useInsecureCookies = isLocalhost || isTestDomain

  return {
    domain: getCookieDomain(hostname),
    // We generally want to use the "secure" attribute in client-side cookies,
    // which means they will only be sent over HTTPS. But in local development
    // we often do not have HTTPS.
    secure: !useInsecureCookies,
    path: '/',
  }
}

//@ts-ignore
export const setCookieDefaults = () => (Cookies.defaults = getCookieSettings())
