import Cookies from 'js-cookie'

import {
  COOKIE_FULL_STORY_ENABLED,
  FULL_STORY_TRACKING_PATHS,
} from '../../src/constants'

/**
 * Determines if the user should have FullStory loaded.
 * Will determine if FS should be loaded based on a cookie flag,
 * or if it does not exist, bucket half of users into FS.
 */
export const getFullStoryEnabled = (path: string) => {
  // will return a string
  let fsEnabled = Cookies.get(COOKIE_FULL_STORY_ENABLED)

  // if the cookie is not set
  if (!fsEnabled) {
    // enable for 25% of users
    fsEnabled = String(Math.random() > 0.75)

    // enable for certain path pages
    if (FULL_STORY_TRACKING_PATHS.includes(path)) {
      fsEnabled = 'true'
    }

    Cookies.set(COOKIE_FULL_STORY_ENABLED, fsEnabled)
  }

  return fsEnabled === 'true'
}
