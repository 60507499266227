//@ts-nocheck
import { analytics } from '@/hooks/useAnalytics'

import { USE_GENERALIZED_TRACKER, emitCMSEvent } from './generalizedEvent'

/**
 * Wraps the Segment window.analytics.track event
 * @param event
 * @param properties
 * @param options
 * @param callback
 */
const legacyTrackSegmentEvent = ({
  event,
  properties = {},
  options,
  callback,
}: {
  event?: string
  properties?: Record<string, any>
  options?: any
  callback?: any
} = {}): void => {
  if (typeof window === 'undefined') return

  if (properties.client === undefined) {
    properties.client = 'cms-next'
  }
  if (analytics && analytics.track) {
    analytics.track(event, properties, options, callback)
  }
}

export const trackSegmentEvent = USE_GENERALIZED_TRACKER
  ? emitCMSEvent
  : legacyTrackSegmentEvent

export const identifySegmentEvent = ({
  userId,
  traits,
  callback,
}: {
  userId?: string
  traits?: Record<string, any>
  callback?: any
} = {}): void => {
  if (typeof window === 'undefined') return

  if (traits.client === undefined) {
    traits.client = 'cms-next'
  }
  if (analytics && analytics.identify) {
    analytics.identify(
      userId,
      traits,
      { integrations: { Salesforce: true } },
      callback
    )
  }
}
