import { getAttributionCookie } from 'lib/@getethos/analytics/getAttributionCookie'
import { setAndGetUserUuid } from 'lib/FeaturesAndExperiments/setAndGetUserUUID'
import { set } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { isNonAgentPartner } from 'src/shared/partners/nonAgentPartners'

enum DeviceType {
  PHONE = 'phone',
  TABLET_AND_UP = 'tabletAndUp',
}

interface LandingPageAttributes {
  utmSource: string | null
  utmCampaign: string | null
  utmMedium: string | null
  utmTerm: string | null
  utmContent: string | null
  referrer: string
  deviceType: DeviceType
  eCampaignId: string | null
  eAdId?: string | null
  p?: string | null
}
const landingPageAttributes: LandingPageAttributes = {
  utmSource: null,
  utmCampaign: null,
  utmMedium: null,
  utmTerm: null,
  utmContent: null,
  referrer: '',
  deviceType: DeviceType.PHONE, // defaults to phone if window.screen is somehow not available
  eCampaignId: null,
  p: null,
}

const urlAttributes: Record<string, string> = {
  utmSource: 'utm_source',
  utmCampaign: 'utm_campaign',
  utmMedium: 'utm_medium',
  utmTerm: 'utm_term',
  utmContent: 'utm_content',
  eCampaignId: 'e_campaign_id',
  eAdId: 'e_ad_id',
  p: 'p',
}

let userId = uuidv4()

export const useLandingPageAttributes = () => {
  // set userId to Segment's anonymousId if available
  if (typeof window !== 'undefined') {
    userId = setAndGetUserUuid()

    // check window search for attribute parameters
    const trackCookie = getAttributionCookie() as Record<
      keyof typeof urlAttributes,
      any
    >
    const queryParams = new URLSearchParams(window.location.search.slice(1))
    Object.keys(urlAttributes).forEach((key) => {
      const param = queryParams.get(
        urlAttributes[key as keyof typeof urlAttributes]
      )
      const cookieValue = trackCookie[key]
      if (param) {
        // set partner code as optimizely attribute if non agent
        if (key === 'p') {
          const partnerCode = param
          const isNonAgent = isNonAgentPartner({ partnerCode })
          const partnerCodeAttribute = isNonAgent ? null : partnerCode
          set(landingPageAttributes, key, partnerCodeAttribute)
        } else {
          set(landingPageAttributes, key, param)
        }
      } else if (cookieValue) {
        set(landingPageAttributes, key, cookieValue)
      }
    })
    if (document.referrer) {
      landingPageAttributes.referrer = document.referrer
    } else if (trackCookie.referrer) {
      landingPageAttributes.referrer = trackCookie.referrer
    }

    // check screen width and set deviceType attribute
    if (window.screen && window.screen.width !== undefined) {
      const screenWidth = window.screen.width
      const deviceType =
        screenWidth >= 600 ? DeviceType.TABLET_AND_UP : DeviceType.PHONE
      set(landingPageAttributes, 'deviceType', deviceType)
    }
  }

  return { landingPageAttributes, userId }
}
