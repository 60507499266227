import { isProduction, isStaging } from '@/constants'
import { datadogLogs } from '@datadog/browser-logs'
import { setAndGetUserUuid } from 'lib/FeaturesAndExperiments/setAndGetUserUUID'

const getEnv = (): string => {
  if (isProduction) return 'production'
  if (isStaging) return 'staging'
  return 'development'
}

const config = {
  clientToken:
    process.env.DATADOG_CLIENT_TOKEN || 'pub455e00229c25a35d0fa3cf8fa51025d1', // Ensure this is set in environment variables
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  service: 'cms-frontend',
  sessionSampleRate: 100,
  env: getEnv(),
}

class ClientLogger {
  private static instance: ClientLogger
  private constructor() {
    datadogLogs.init(config)
  }

  public static getInstance(): ClientLogger {
    if (!ClientLogger.instance) {
      ClientLogger.instance = new ClientLogger()
    }
    return ClientLogger.instance
  }

  public info(message: string, meta?: Record<string, unknown>): void {
    const sessionId = setAndGetUserUuid()
    datadogLogs.logger.info(message, {
      ...meta,
      anonymousId: sessionId,
    })
  }

  public warn(message: string, meta?: Record<string, unknown>): void {
    const sessionId = setAndGetUserUuid()
    datadogLogs.logger.warn(message, {
      ...meta,
      anonymousId: sessionId,
    })
  }

  public error(
    message: string,
    meta?: Record<string, unknown>,
    error?: Error
  ): void {
    const sessionId = setAndGetUserUuid()

    console.log('error', error)
    datadogLogs.logger.error(
      message,
      {
        ...meta,
        anonymousId: sessionId,
      },
      error
    )
  }

  public debug(message: string, meta?: Record<string, unknown>): void {
    datadogLogs.logger.debug(message, meta)
  }
}
/**
 * ClientLogger is a class that logs messages to DD from browser. Should be used in client-side code.
 */
export const browserLogger = ClientLogger.getInstance()
