/* eslint-disable */
export const initialize = () => {
  // Gatsby server side rendering doesn't have a global window: https://www.gatsbyjs.org/docs/debugging-html-builds/
  if (typeof window === 'undefined') return

  // Check to see if customer.io has already been loaded
  if (window._cio && window._cio.identify) return

  const siteId = process.env.NEXT_PUBLIC_CUSTOMERIO_SITE_ID

  const envs = ['production', 'staging', 'netlify']
  window._cio = window._cio || []
  if (envs.includes(process.env.NEXT_PUBLIC_ETHOS_ENV)) {
    ;(function () {
      var a, b, c
      a = function (f) {
        return function () {
          window._cio.push([f].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }
      b = ['load', 'identify', 'sidentify', 'track', 'page']
      for (c = 0; c < b.length; c++) {
        window._cio[b[c]] = a(b[c])
      }
      var t = document.createElement('script')
      var s = document.getElementsByTagName('script')[0]
      t.async = true
      t.id = 'cio-tracker'
      t.setAttribute('data-site-id', siteId)
      t.src = 'https://assets.customer.io/assets/track.js'
      s.parentNode.insertBefore(t, s)
    })()
  }
}

export const identify = ({ campaign, email }) => {
  if (window._cio && window._cio.identify) {
    const timestamp = Math.floor(new Date().getTime() / 1000)
    window._cio.identify({
      id: email,
      email,
      created_at: timestamp,
      campaign,
    })
  }
}

export const track = ({ event, ...rest }) => {
  if (window._cio && window._cio.track) {
    window._cio.track(event, { ...rest })
  }
}
