import { isProduction } from '@/constants'
import { createInstance } from '@optimizely/react-sdk'
import { browserLogger } from 'lib/Logger/browserLogs'
import { memoize } from 'lodash'

import { getOptimizelyKey } from './getOptimizelyKey'
import { registerFullstackListeners } from './registerFullstackListeners'

const getOptimizelyConfig = () => {
  if (isProduction) {
    try {
      const optimizelyDatafile = require('../optimizely/datafile.json')
      return { datafile: optimizelyDatafile }
    } catch (error) {
      browserLogger.error(
        '[Optimizely] - Failed to retrieve datafile, fallbacking to API',
        {
          error,
        }
      )
      return { sdkKey: getOptimizelyKey() }
    }
  }
  return { sdkKey: getOptimizelyKey() }
}

export const optimizelyInstance = memoize(() => {
  try {
    const config = getOptimizelyConfig()

    const instance = createInstance({
      ...config,
      odpOptions: {
        disabled: true,
      },
    })

    registerFullstackListeners(instance)

    return instance
  } catch (error) {
    console.error('Failed to retrieve optimizelyInstance', error)
  }
})
