import { EVENT_NAMES } from './constants'
import { AnalyticsIntermediateFunctionType, emitEvent } from './emitEvent'

/**
 * commented out Jan 2023
 * TODO: reconsider Joi as a dependency
 */
// import Joi from '@hapi/joi'
// import { eventSchema } from './schema'

// For now the feature flag for switching between the old and refactored code is a mere boolean
// We will turn it into its final form, whatever that may be, in a future PR
export const USE_GENERALIZED_TRACKER = false

// When we no longer have to support the old and refactored code simultaneously, this will become
// the code for the only Segment-related track function in existence
// However, those changes will only occur after we have tested the code in PROD, with our feature flag
// set up for a quick and easy reversion if necessary
export const intermediateTrack: AnalyticsIntermediateFunctionType = (
  eventName,
  props,
  destination
) => {
  const properties = props.properties ?? { client: 'CMS-next' }
  const options = {
    ...props?.options,
    integrations: destination || props?.options?.integrations || {},
  }

  if (properties.client === undefined) {
    properties.client = 'cms-next'
  }

  //@ts-ignore
  window?.analytics?.track?.(eventName, properties, options, props.callback)
}

// This variable contains the event dictionary entries for all Segment events
// If a developer creates a new Segment event they will need to update this dictionary with a new entry
// If no matching dictionary entry exists then the Default option will be used.
// However, this will only occur if USE_GENERALIZED_TRACKER is set to true; if it is set to false, the old schema-less workflow will be used instead

const CMSEventDictionary = {
  [EVENT_NAMES.EXPERIMENT_ACTIVATED]: {
    activity: [
      {
        destination: {
          All: false,
          'Google Analytics': true,
          FullStory: true,
          'Customer.io': true,
        },
        action: intermediateTrack,
      },
    ],
  },
  [EVENT_NAMES.FEATURE_DECISION_MADE]: {
    activity: [
      {
        destination: {
          All: false,
          'Google Analytics': true,
        },
        action: intermediateTrack,
      },
    ],
  },
  Default: {
    activity: [
      {
        destination: {}, // These events are aimed at Segment's default zero-destination information location (All: true)
        action: intermediateTrack,
      },
    ],
  },
}

export function emitCMSEvent(props: any): void {
  // Run schema guard
  /**
   * commented out Jan 2023
   * TODO: reconsider Joi as a dependency
   */
  // Joi.attempt({ event: props.event, properties: props.properties }, eventSchema)

  // Here we would call the generalized function shared from the Ethos codebase
  // For now we call the copied-over function instead
  emitEvent(props.event, props, CMSEventDictionary)
}
