import Cookies from 'js-cookie'

import { ATTRIBUTION_COOKIE_NAME } from './constants'

export const getAttributionCookie = (): object => {
  const base64: string = Cookies.get(ATTRIBUTION_COOKIE_NAME) || ''
  try {
    return JSON.parse(window.atob(base64))
  } catch (e) {
    return {}
  }
}
