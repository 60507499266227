export const ACTIVATED_EXPERIMENTS = 'ethos_allActivatedExperiments'
export const LATEST_EXPERIMENT = 'ethos_latestExperiment'
const AE_DELIM = '-'
const LE_DELIM = '|'

const appendToLocalStorage = (experimentKey = '', id: string): void => {
  if (typeof experimentKey !== 'string') return

  try {
    // don't overwrite current list!
    const current = window.localStorage.getItem(ACTIVATED_EXPERIMENTS) || ''

    const latest = `${current}|${experimentKey}${AE_DELIM}${id}`

    window.localStorage.setItem(ACTIVATED_EXPERIMENTS, latest)
  } catch (err) {
    console.warn('no local storage; experiment not appended global', err)
  }
}

export const setLocalStorageExperiment = ({
  experimentKey,
  variationKey,
  id,
}: {
  experimentKey: string
  variationKey: string
  id: string
}): void => {
  try {
    window.localStorage.setItem(
      LATEST_EXPERIMENT,
      `${experimentKey}${LE_DELIM}${variationKey}${LE_DELIM}${id}`
    )
    appendToLocalStorage(experimentKey, id)
  } catch (err) {
    console.warn('no local storage; experiment not appended latest', err)
  }
}

export const experimentPreviouslyActivated = (
  experimentKey: string,
  id: string
): boolean => {
  if (typeof experimentKey !== 'string') return false

  try {
    const current = window.localStorage.getItem(ACTIVATED_EXPERIMENTS) || ''
    return current.includes(`${experimentKey}${AE_DELIM}${id}`)
  } catch (err) {
    console.warn('no local storage; previously activated unknown', err)
    return false
  }
}

export function checkForOverride({
  experimentName,
  search,
  variants,
}: {
  experimentName: string
  search: string
  variants: string[]
}): string {
  let variation = ''
  const searchParams = new URLSearchParams(search)
  // sessionStorage.getItem returns null if item not found
  const searchParamVariation =
    (searchParams.get(experimentName) ||
      sessionStorage.getItem(experimentName)) ??
    ''

  if (variants.includes(searchParamVariation)) {
    variation = searchParamVariation
    sessionStorage.setItem(experimentName, searchParamVariation)
  }

  return variation
}
