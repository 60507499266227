//@ts-nocheck
import { ReactSDKClient, enums } from '@optimizely/react-sdk'

import { EVENT_NAMES } from '../@getethos/analytics/constants'
import { trackSegmentEvent } from '../@getethos/analytics/trackSegmentEvent'
import {
  appendFeatureToSessionStorage,
  featurePreviouslyChecked,
} from '../@getethos/interfaces/FeatureFlagConfig'
import {
  experimentPreviouslyActivated,
  setLocalStorageExperiment,
} from './experimentStore'

/**
 * Fired in the first call of Optimizely `action.applied`
 * Disables "masking" stylesheet to help with flicker
 */
let syncChangesApplied = false

/**
 * This value is true as of Jan 2023, this is the p90 for Optimzely
 * load according to Datadog RUM. If we don't have Optimizely by now,
 * user is likely using an ad blocker.
 */
const MASK_TIMEOUT = 1500

const removeAntiflickerMask = function (): void {
  if (syncChangesApplied) return
  try {
    const styleNode = document.querySelector('style#optimizely-mask')
    if (styleNode) {
      // @ts-expect-error
      styleNode.disabled = true
    }
  } catch (err) {
    // do nothing
  }
  syncChangesApplied = true
}

function onFeatureDecisionTrackEvent(decisionObject): void {
  if (decisionObject.type === 'flag') {
    const {
      type,
      userId: optimizelyUserId,
      decisionInfo: { enabled, flagKey, variationKey },
    } = decisionObject

    // Integrations settings for only GA
    const onDecisionDestinations = {
      All: false,
      'Google Analytics': true,
    }

    const previouslyChecked = featurePreviouslyChecked(
      flagKey,
      optimizelyUserId,
      enabled
    )

    if (!previouslyChecked) {
      appendFeatureToSessionStorage(flagKey, optimizelyUserId, enabled)

      trackSegmentEvent({
        event: EVENT_NAMES.FEATURE_DECISION_MADE,
        properties: {
          type,
          optimizelyUserId,
          featureEnabled: enabled,
          featureKey: flagKey,
          variationKey,
          nonInteraction: 1,
          client: 'cms-next',
        },
        options: {
          integrations: onDecisionDestinations,
        },
      })
    }
  }
  if (decisionObject.type === 'feature') {
    const {
      type,
      userId: optimizelyUserId,
      decisionInfo: { featureEnabled, featureKey },
    } = decisionObject

    // Integrations settings for only GA
    const onDecisionDestinations = {
      All: false,
      'Google Analytics': true,
    }

    const previouslyChecked = featurePreviouslyChecked(
      featureKey,
      optimizelyUserId,
      featureEnabled
    )

    if (!previouslyChecked) {
      appendFeatureToSessionStorage(
        featureKey,
        optimizelyUserId,
        featureEnabled
      )

      trackSegmentEvent({
        event: EVENT_NAMES.FEATURE_DECISION_MADE,
        properties: {
          type,
          optimizelyUserId,
          featureEnabled,
          featureKey,
          nonInteraction: 1,
          client: 'cms-next',
        },
        options: {
          integrations: onDecisionDestinations,
        },
      })
    }
  }
}

function onExperimentActivatedTrackEvent(activateObject): void {
  const {
    userId: optimizelyUserId,
    experiment: { key: experimentKey },
    variation: { key: variationKey },
  } = activateObject
  // Integrations settings to only allow GA, FullStory, and Customer.io
  const onActivateDestinations = {
    All: false,
    'Google Analytics': true,
    FullStory: true,
    'Customer.io': true,
  }

  const previouslyActivated = experimentPreviouslyActivated(
    experimentKey,
    optimizelyUserId
  )

  if (previouslyActivated) return

  trackSegmentEvent({
    event: EVENT_NAMES.EXPERIMENT_ACTIVATED,
    properties: {
      experimentKey,
      variationKey: variationKey,
      optimizelyUserId,
      nonInteraction: 1,
      client: 'cms-next',
    },
    options: {
      integrations: onActivateDestinations,
    },
  })

  // save to local storage for activate vs getVariation in getExperimentValue
  // todo [GC-787] -- implement as part of holdout group feature https://ethoslife.atlassian.net/browse/GC-787
  setLocalStorageExperiment({
    experimentKey,
    variationKey,
    id: optimizelyUserId,
  })
}

export const registerFullstackListeners = (client: ReactSDKClient): void => {
  // When a Decision is made about a feature, sends a Segment Track event
  client.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    onFeatureDecisionTrackEvent
  )

  // When an Experiment is Activated, sends a Segment Track event
  client.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.ACTIVATE,
    onExperimentActivatedTrackEvent
  )

  // on first activate call, remove antiflicker mask if needed
  client.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.ACTIVATE,
    removeAntiflickerMask
  )

  // if there was a problem initializing optimizely (adblocker, etc.), just add a timeout
  setTimeout(removeAntiflickerMask, MASK_TIMEOUT)
}
