import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

export const USER_UUID_COOKIE_NAME = 'USER_UUID'
export const ANONYMOUS_ID = 'ajs_anonymous_id'

const USER_UUID_COOKIE_EXPIRY = 180

/**
 * Generates and stores a User UUID or returns the existing User UUID
 */
export function setAndGetUserUuid() {
  let userUuid = Cookies.get(USER_UUID_COOKIE_NAME)

  if (!userUuid) {
    userUuid = uuidv4()
    Cookies.set(USER_UUID_COOKIE_NAME, userUuid, {
      expires: USER_UUID_COOKIE_EXPIRY,
    })
  }

  return userUuid
}

/**
 * Returns the a UUID for the request header. Will return the Segment anonymous
 * id if segment is initialized or the User UUID as a fallback
 * @return {*}
 */
export function getRequestUuid() {
  const anonymousId = Cookies.get(ANONYMOUS_ID)
  if (anonymousId) return anonymousId

  return setAndGetUserUuid()
}
