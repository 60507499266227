// This is a placeholder for the AnalyticsIntermediateFunctionType type that will, in the future, be shared from the Ethos codebase
// For now it is just a direct copy of code from there
// with small edits to make it easier to read
export type AnalyticsIntermediateFunctionType = (
  eventName: string,
  props: {
    properties: Record<string, any>
    options?: any
    callback?: any
  },
  destination: any // We will replace this with MartechDestinationType,
) => void
type MartechActionType = {
  destination: any // We will replace this with MartechDestinationType,
  action: AnalyticsIntermediateFunctionType
  transformation?: any // We will replace this with a description of the function
}
type MartechActivity = Record<'activity', MartechActionType[]>
type EventDictionary = Record<string, MartechActivity>

// This is a placeholder for the emitEvent function that will, in the future, be shared from the Ethos codebase
// It is just a copy of that code
export function emitEvent(
  eventName: string,
  props: any,
  eventDictionary: EventDictionary
): void {
  const eventNameOrDefault =
    eventName in eventDictionary ? eventName : 'Default'
  const activity = eventDictionary[eventNameOrDefault]['activity']
  activity.forEach((destinationAndAction: MartechActionType) => {
    const actualProps = destinationAndAction?.transformation?.(props) ?? props
    destinationAndAction.action(
      eventName,
      actualProps,
      destinationAndAction.destination
    )
  })
}
