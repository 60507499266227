import { validate } from 'uuid'

import { analytics } from '@/hooks/useAnalytics'

const EXTERNAL_ID = 'external_id'

/**
 * ----- CLIENT-SIDE -----
 *
 * Automatically set the anonymous_id from external_id query param
 */
export const getAndSetAnonymousIDFromParamClientSide = (): void => {
  if (typeof window === 'undefined') return
  const query = new URLSearchParams(window.location.search)
  const anonymousId = query.get(EXTERNAL_ID)
  if (anonymousId && validate(anonymousId)) {
    analytics.setAnonymousId(anonymousId)
  }
}
