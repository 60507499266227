import { NextWebVitalsMetric } from 'next/app'

const VITALS_TO_TRACK = ['FCP', 'LCP', 'CLS', 'FID', 'INP']

export const formatWebVitalsMetric = (metric: NextWebVitalsMetric) => {
  if (!VITALS_TO_TRACK.includes(metric.name)) return null

  const paintMetrics = metric.name === 'FCP' || metric.name === 'LCP'
  const value = paintMetrics ? metric.value / 1000 : metric.value
  const id = metric.id.split('-').slice(0, 2).join('-')

  const event = {
    name: metric.name,
    id,
    value: Number(value.toFixed(3)),
    label: metric.label,
  }

  return event
}
