// We currently maintain 2 copoies of this constant.
// The other one is on the main app at frontend/shared/lib/analytics/constants.ts
// If you make changes to this one, make sure you do the same for the other one
// API Agreement: https://docs.google.com/spreadsheets/d/1XM66Ril-VpurYlC9tVaDWAM1wnO9eSXcXgRpPO_JN5I/edit?usp=sharing
export const PREFILL_PII_PROPS = [
  'address',
  'address_2',
  'birth_date',
  'city',
  'credit_score',
  'email',
  'first_name',
  'gender',
  'health',
  'last_name',
  'middle_name',
  'name',
  'phone',
  'state',
  'tobacco',
  'zip_code',
  'age',
  'utm_address',
  'utm_address_2',
  'utm_birth_date',
  'birthdate',
  'utm_birthdate',
  'dob',
  'utm_dob',
  'utm_city',
  'utm_credit_score',
  'creditscore',
  'utm_creditscore',
  'credit_rating',
  'utm_credit_rating',
  'utm_email',
  'utm_first_name',
  'fname',
  'utm_fname',
  'utm_gender',
  'utm_health',
  'utm_last_name',
  'lname',
  'utm_lname',
  'utm_middle_name',
  'mname',
  'utm_mname',
  'utm_name',
  'contact',
  'utm_contact',
  'utm_phone',
  'utm_state',
  'utm_tobacco',
  'smoker',
  'utm_smoker',
  'utm_zip_code',
  'zipcode',
  'zipCode',
  'birthDate',
  'utm_zipcode',
  'zip',
  'utm_zip',
  'utm_age',
  'utmAge',
  'param1',
  'p.param1',
  'firstName',
  'lastName',
  'firstname',
  'lastname',
]

export const TERM_KEY = 'utm_term'

const QUINSTREET_TERM: string = '3198679'
const QUINSTREET_SOURCE: string = 'quinstreet'

export const ENCRYPTION_TERM_SOURCES: { [x: string]: string } = {
  [QUINSTREET_TERM]: QUINSTREET_SOURCE,
}
